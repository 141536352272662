<template>
  <b-card>
    <b-row
      align-h="between"
      align-v="center"
      class="mx-2 px-0"
    >
      <b-col
        lg="12"
        md="12"
        sm="12"
      >
        <b-row
          align-h="between"
        >
          <b-col
            lg="10"
            md="11"
            sm="11"
          >
            <b-row
              align-h="start"
              align-v="center"
            >
              <b-col
                lg="3"
                md="3"
                sm="3"
                xs="12"
                class="px-0  mx-0 mt-1 mr-1"
              >
                <b-form-group class="mb-0">
                  <b-form-select
                    v-model="filter"
                    :options="typeArrayRefresh"
                  />
                </b-form-group>
              </b-col>
              <b-col
                lg="3"
                md="3"
                sm="3"
                class="px-0 mt-1"
              >
                <b-form-group
                  class="mb-0 mr-0"
                >
                  <b-input-group>
                    <flat-pickr
                      v-model="fromDate"
                      class="form-control"
                      :config="{ wrap: true, enableTime: true, enableSeconds: true, dateFormat: 'd/m/y H:i:S'}"
                      :placeholder="( $t('user.place_holder') )"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <label class="ml-1 mt-1 mr-1 text-center">{{ $t('common.to') }}</label>
              <b-col
                cols="12"
                lg="3"
                md="3"
                sm="3"
                class="px-0 mt-1"
              >
                <b-form-group
                  class="mb-0 mr-0"
                >
                  <b-input-group>
                    <flat-pickr
                      v-model="toDate"
                      class="form-control"
                      :config="{ wrap: true, enableTime: true, enableSeconds: true, dateFormat: 'd/m/y H:i:S'}"
                      :placeholder="( $t('user.place_holder') )"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            lg="2"
            md="1"
            sm="1"
          >
            <b-row
              align-h="end"
            >
              <b-button
                class="mt-1 px-2"
                type="reset"
                variant="primary"
                @click="sortOptions"
              >
                {{ $t('common.filter') }}
              </b-button>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="mx-2 mt-1">
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="itemsFiltered"
        :fields="fieldsRefresh"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter-included-fields="filterOn"
        show-empty
        @filtered="onFiltered"
      >
        <template #empty="scope">
          <b-row align-h="center">
            <span>{{ scope.emptyText }}</span>
          </b-row>
        </template>

        <template #cell(event)="items">
          <div class="d-flex align-items-center">
            <b-avatar
              size="32"
              :variant="`light-${statusVariant( items['item'].event_code.split('_')[1])}`"
            >
              <feather-icon
                :icon="avatarIconAndVariant( items['item'].event_code.split('_')[0]).icon"
              />
            </b-avatar>
            <div class="pl-1">
              <div>
                {{ $t('filter.' + items['item'].event_code.split('_')[0].toLowerCase()) }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(status)="cellStatus">
          <b-badge :variant="`light-${statusVariant(cellStatus['item'].event_code.split('_')[1])}`">
            {{ $t('filter.' + cellStatus['item'].event_code.split('_')[1].toLowerCase()) }}
          </b-badge>
        </template>
        <template #cell(date)="date">
          <div class="d-flex align-items-center">
            {{ date['item'].created_at }}
          </div>
        </template>
        <template #cell(user)="user">
          <div
            v-if="user['item'].user != null"
            class="d-flex align-items-center"
          >
            {{ user['item'].user }}
          </div>
          <div
            v-else
            class="d-flex align-items-center"
          >
            {{ $t('filter.undefined') }}
          </div>
        </template>
      </b-table>
    </div>
    <!-- pagination -->
    <b-card-body class="d-flex justify-content-between align-items-center flex-wrap pt-0">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
      <!-- page length -->
      <span class="mx-0">{{ $t('common.displaying') }} {{ perPage * currentPage > totalRows ? totalRows : perPage * currentPage }} {{ $t('common.of') }} {{ totalRows }}</span>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BCardBody,
  BCard,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import dbSystem from '@/models/system'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BCardBody,
    BCard,
    BCol,
    BRow,
    BButton,
    flatPickr,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Array,
      default: () => [],
    },
    totalRows: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      fromDate: '',
      toDate: '',
      filter: '',
      filterOp: null,
      itemsFiltered: [],
      fieldsRefresh: [],
      statusRefresh: [],
      typeArray: [],
      typeArrayRefresh: [],
      types: [],
      filterOk: '',
      events: [],
    }
  },
  async created() {
    this.system()
    this.languageRefresh()
  },
  mounted() {
    this.languageRefresh()
  },
  beforeUpdate() {
    this.languageRefresh()
  },
  methods: {
    async system() {
      const arrayLower = []
      const response = await dbSystem.get()
      if (response !== null) {
        this.types = response.logs_event_codes
        this.types.forEach((type, index) => {
          arrayLower.push(type.toLowerCase())
          this.typeArray.push(arrayLower[index].split('_'))
          this.languageRefresh()
        })
      } else {
        this.typeArray = []
      }
      this.itemsFiltered = JSON.parse(JSON.stringify(this.items))
    },
    avatarIconAndVariant(status) {
      if (status === 'PRODUCT') return { variant: 'primary', icon: 'ShoppingBagIcon' }
      if (status === 'USER') return { variant: 'success', icon: 'UsersIcon' }
      if (status === 'STOCK') return { variant: 'blue', icon: 'PackageIcon' }
      if (status === 'CATEGORY') return { variant: 'green', icon: 'LayersIcon' }
      if (status === 'BRAND') return { variant: 'secondary', icon: 'AtSignIcon' }
      if (status === 'PROVIDER') return { variant: 'purple', icon: 'RadioIcon' }
      if (status === 'AGENDA') return { variant: 'purple', icon: 'ClockIcon' }
      if (status === 'INVOICES') return { variant: 'purple', icon: 'DollarSignIcon' }
      if (status === 'CUSTOMER') return { variant: 'purple', icon: 'UserIcon' }
      if (status === 'BUDGET') return { variant: 'purple', icon: 'FileTextIcon' }
      return { variant: 'secondary', icon: 'XIcon' }
    },
    statusVariant(status) {
      if (status === 'GET') return 'primary'
      if (status === 'SAVE') return 'success'
      if (status === 'DELETE') return 'danger'
      // if (status === 'Downloaded') return 'secondary'
      // if (status === 'Draft') return 'warning'
      // if (status === 'Sent') return 'info'
      return 'primary'
    },
    sortOptions() {
      let item = []
      const filtered = []
      let filterDone = ''
      let statusDone = ''
      const hour = []
      const date = []
      const dateSplit = []
      const hourSplit = []
      const dateFormated = []
      let fromDate = null
      let fromDateSpace = null
      let fromDateDate = null
      let fromDateTime = null
      let toDate = null
      let toDateSpace = null
      let toDateDate = null
      let toDateTime = null

      fromDateSpace = this.fromDate.split(' ')
      fromDateDate = fromDateSpace[0].split('/')
      fromDateTime = fromDateSpace[1].split(':')
      // eslint-disable-next-line prefer-template
      fromDate = new Date('20' + fromDateDate[2], fromDateDate[1] - 1, fromDateDate[0], fromDateTime[0], fromDateTime[1], fromDateTime[2])

      toDateSpace = this.toDate.split(' ')
      toDateDate = toDateSpace[0].split('/')
      toDateTime = toDateSpace[1].split(':')
      // eslint-disable-next-line prefer-template
      toDate = new Date('20' + toDateDate[2], toDateDate[1] - 1, toDateDate[0], toDateTime[0], toDateTime[1], toDateTime[2])

      this.items.forEach(data => {
        item = data.created_at.split(' ')
        date.push(item[0])
        hour.push(item[1])
      })
      date.forEach((_, index) => {
        dateSplit.push(date[index].split('/'))
        hourSplit.push(hour[index].split(':'))
        // eslint-disable-next-line prefer-template
        dateFormated[index] = new Date('20' + dateSplit[index][2], dateSplit[index][1] - 1, dateSplit[index][0], hourSplit[index][0], hourSplit[index][1])
      })

      this.filterOk = this.filter
      // filterUp = this.filterOk.toUpperCase()
      filtered.push(this.filterOk.split(' '))
      // eslint-disable-next-line prefer-destructuring
      filterDone = filtered[0][0]
      // eslint-disable-next-line prefer-destructuring
      statusDone = filtered[0][1]
      // eslint-disable-next-line prefer-template
      this.itemsFiltered = this.items.filter((_, index) => dateFormated[index] >= fromDate && dateFormated[index] <= toDate && `${this.$i18n.t('filter.' + this.items[index].event_code.split('_')[0].toLowerCase())}` === filterDone && `${this.$i18n.t('filter.' + this.items[index].event_code.split('_')[1].toLowerCase())}` === statusDone).map(f => f)

      this.totalRows = this.itemsFiltered.length
    },
    languageRefresh() {
      // this.itemsFiltered = JSON.parse(JSON.stringify(this.items))
      this.fieldsRefresh = JSON.parse(JSON.stringify(this.fields))
      this.typeArrayRefresh = JSON.parse(JSON.stringify(this.typeArray))

      this.typeArrayRefresh.forEach((_, index) => {
        // eslint-disable-next-line prefer-template
        this.typeArrayRefresh[index] = this.$i18n.t(('filter.' + this.typeArray[index][0])) + ' ' + this.$i18n.t('filter.' + this.typeArray[index][1])
      })

      this.fieldsRefresh.forEach((_, index) => {
        this.fieldsRefresh[index].label = `${this.$i18n.t(this.fields[index].label)}`
      })
    },
    // clearFilter() {
    //   this.fromDate = ''
    //   this.toDate = ''
    // },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    updatePerPage(size) {
      this.$emit('onPerPage', size)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
