<template>
  <b-card-code>
    <b-card-body>
      <UsersLogTable
        :fields="fields"
        :items="items"
        :status="status"
        :total-rows="perPage"
        @reset="reset"
        @onPerPage="onPerPage"
      />
      {{ perPage }}
    </b-card-body>
  </b-card-code>
</template>
<script>
import {
  BCardBody,
} from 'bootstrap-vue'
import BCardCode from '@/views/drs/components/BCardCode.vue'
import UsersLogTable from '@/views/drs/users/components/UsersLogTable.vue'
import dbLog from '@/models/log'

export default {
  name: 'Logs',
  components: {
    BCardCode,
    BCardBody,
    UsersLogTable,
  },
  data: () => ({
    items: [],
    perPage: 10,
    fields: [
      { key: 'event', label: 'user.event', sortable: true },
      { key: 'status', label: 'common.status', sortable: true },
      { key: 'user', label: 'budget.user', sortable: true },
      { key: 'date', label: 'budget.date', sortable: true },
    ],
    status: [{
      1: 'common.yes', 0: 'common.no',
    },
    {
      1: 'light-success', 2: 'light-danger',
    }],
  }),
  async created() {
    await this.fetch(1)
    await this.fetch(1)
  },
  methods: {
    reset() {
      this.fetch(1)
      this.settings = {}
      this.perPage = 10
    },
    async fetch(page) {
      this.isBusy = true
      const response = await dbLog.get({ page, perPage: this.perPage, paginate: true })
      this.isBusy = false
      this.items = JSON.parse(JSON.stringify(response.data))
      this.perPage = response.total
      delete response.data
      this.settings = response
    },
    onPagination(page) {
      this.fetch(page)
    },
    onPerPage(size) {
      this.perPage = size
      this.fetch(1)
    },
  },
}
</script>
<style scoped>

</style>
